import React from "react";

const title = "MATTEO's";
const subtitle = "QUIZ";

class Start extends React.Component{
	render (){
		return (
			<div className="start">
				<div>
					<h1>{
						title.split("").map((x, i) => <span key={i}>{x}</span>)
					}</h1>
					<h2>{subtitle}</h2>
				</div>
				<button className="btn"><div className="inner">START</div></button>
			</div>
		)
	}
}

export default Start;
