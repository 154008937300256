import React from "react";

function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

class Landscape extends React.Component{
	renderClouds = () =>{
		const types = ["tiny", "small", "normal", "large"]
		const clouds = [];
		for(let i = 1; i <= 12; i++){
			clouds.push(
				<div key={"cloud-"+i} className={"cloud cloud-"+i+" "+(types[getRandomInt(0,4)])}><div/><div/><div/><div/></div>
			)
		}
		return clouds;
	}
	render (){
		return (
			<div className="landscape">
				{this.renderClouds()}
				<div className="mountains"/>
				<div className="background"/>
			</div>
		)
	}
}

export default Landscape;
